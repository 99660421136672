import React, { useEffect } from 'react'
import FAQ from './FAQ/FAQ';
import './HomePage.css';
import { useState } from 'react';
import StarImage from '../../Assets/DigiesimStars.png';
import BannerImg from '../../Assets/DigiesmManImage.png'
import { MagnifyingGlass } from 'react-loader-spinner';
import { SearchRounded } from '@mui/icons-material';
import Slider from './Slider/Slider';
import Footer from './Footer/Footer';
import AppSection from './AppSection/AppSection';
import Logo_1 from '../../Assets/Logo_1.png';
import Logo_2 from '../../Assets/Logo_2.png';
import Logo_3 from '../../Assets/Logo_3.png';
import Logo_4 from '../../Assets/Logo_4.png';
import Logo_5 from '../../Assets/Logo_5.png';
import Logo_6 from '../../Assets/Logo_6.png';
import WhyDigiesim from './WhyDigiesim/WhyDigiesim';
import FamousRegions from './Landing Page/FamousRegions';
import FamousCountries from './Landing Page/FamousCountries';
import SessionTimeOutImg from '../../Assets/digiesim.png';
import { Bars } from "react-loader-spinner";
import { H1, H2, H3 } from "../../Components/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    Box,
    Button,
    useMediaQuery,
} from "@mui/material";


const HomePage = () => {

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate()
    const customId = "forNotShowingMultipleToast"

    const EmailId = localStorage.getItem('EmailId')

    const isMobile = useMediaQuery("(max-width: 900px)");
    const UserUuid = localStorage.getItem("UserUuid")


    // const handleScroll = (event) => {
    //     event.preventDefault();
    //     const targetId = event.currentTarget.getAttribute('href').substring(1);
    //     const targetElement = document.getElementById(targetId);
    //     window.scrollTo({
    //         top: targetElement.offsetTop,
    //         behavior: 'smooth'
    //     });
    // };

    return (
        <>
            <div className='HomePage' >
                <div className="Banner">
                    <div className="s1">
                        <h1>Stay Connected <br /> everywhere</h1>
                        <p>
                            Get mobile data wherever you travel and avoid expensive roaming charges.
                        </p>

                        <p className='s1-para' >Instant. Easy. Affordable</p>

                        <div className="s1s1">
                            <img src={StarImage} alt="" />
                        </div>
                    </div>

                    <div className="s2">
                        <img src={StarImage} id='RatingImage' />
                        <img src={BannerImg} id='ManImage' />
                    </div>
                </div>
            </div>


            <FamousCountries />

            <FamousRegions />

            <AppSection />

            <div className="providers">
                <h2 style={{ color: '#292b2e', fontSize: '28px', fontWeight: '500' }} >Local networks, Giving you the best price</h2>
                <div className='providersLogo' >
                    <img src={Logo_1} id='companiesLogos' />
                    <img src={Logo_2} id='companiesLogos' />
                    <img src={Logo_3} id='companiesLogos' />
                    <img src={Logo_4} id='companiesLogos' />
                    <img src={Logo_5} id='companiesLogos' />
                    <img src={Logo_6} id='companiesLogos' />
                </div>
            </div>

            <WhyDigiesim />

            <FAQ />

            <Slider />

            <Footer />
        </>
    )
}

export default HomePage
