import './App.css';
import React, { useEffect, useState } from 'react'
import Login from './Components/Login Page/Login';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import SelectedCountryPlan from './Components/Selected Country Plans/SelectedCountryPlan';
import FAQ from './Components/HomePage/FAQ/FAQ';
import { ToastContainer, } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import HomePage from './Components/HomePage/HomePage';
import Payment from './Components/HomePage/Payment/Payment.jsx';
import PaymentSuccess from './Components/Payment/PaymentSuccess.jsx';
import PaymentCancel from './Components/Payment/PaymentCancel.jsx';
import Business from './Components/Business/Business.jsx';
import Country_List from './Components/HomePage/Landing Page/Country_List.jsx';
import Account from './Components/Account/Account.jsx';
import RegionCountryList from './Components/HomePage/Landing Page/RegionCountryList.jsx';
import SelectedRegionPlans from './Components/Selected Regions Plan/SelectedRegionPlans.jsx';
import SessionTimeOutImg from './Assets/digiesim.png';
import MaintenanceImaage from './Assets/digiesim.png'
import {
  Box, Modal, Button,
  useMediaQuery,
} from '@mui/material';
import { H2 } from './Components/Typography.jsx';
import LoaderImage from './Assets/digiesim.png'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";


const modalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  border: "0px",
  outline: "0px"
};

function App() {
  

  return (
    <Router>
      <ToastContainer />
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const showNavbar = (location.pathname !== '/forgot_password' && location.pathname !== '/login');
  const EmailId = localStorage.getItem('EmailId')
  const REACT_APP_BASE_URL_MAINTENANCE = process.env.REACT_APP_BASE_URL_MAINTENANCE
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate()
  const customId = "forNotShowingMultipleToast"
  const [MaintenanceMode, setMaintenanceMode] = useState("")
  const [MaintenanceModeText, setMaintenanceModeText] = useState("")
  const [AllDataResponse, setAllDataResponse] = useState("");

  const FetchMaintenanceMode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL_MAINTENANCE}/get_maintenance`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.maintenance_status == "true") {
          setMaintenanceMode(true)
        } else {
          setMaintenanceMode(false)
        }
        setMaintenanceModeText(result.maintenance_msg)
        setAllDataResponse(result.Status);
      })
  }

  useEffect(() => {
    FetchMaintenanceMode()
  }, [])

  const [OpenSessionTimeOutModal, setOpenSessionTimeOutModal] = useState(false)
  const [Loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const UserUuid = localStorage.getItem("UserUuid")

  const [GetLoginTime, setGetLoginTime] = useState('')
  const TimesGap = 1000 * 60 * 60 * 12// 12 hours in milliseconds

  const GetTheLoginTime = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "email_id": EmailId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL}/get_login_time`, requestOptions)
      .then((response) => response.json())
      .then((result) => { setGetLoginTime(result.login_time); })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn) {

      if (!GetLoginTime) {
        GetTheLoginTime();
      }
    }
  }, [GetLoginTime]);

  useEffect(() => {
    if (!GetLoginTime) return;

    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - GetLoginTime;

      if (timeDifference >= TimesGap) {
        setOpenSessionTimeOutModal(true);
        clearInterval(interval);
      }
    });

    return () => clearInterval(interval);
  }, [GetLoginTime]);



  const HandleLogout = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "user_uuid": UserUuid
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL}/logout`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          navigate('/login');
          setOpenSessionTimeOutModal(false)
          localStorage.clear()
          toast.success(result.Msg, {
            position: "top-right",
            autoClose: 2000,
            toastId: customId
          });
        } else if (result.Status == "0") {
          if (result.Msg === "Account Doesn't Exists") {
            localStorage.clear()
            navigate("/login")
          }
          toast.success("Sucessfully Logged Out", {
            position: "top-right",
            autoClose: 2000,
            toastId: customId
          });
          return;
        }
      })
  }

  // To check the version of the latest vuild and refresh
  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const response = await fetch('/version.json', { cache: 'no-store' });
        if (!response.ok) {
          console.error('Failed to fetch version.json');
          return;
        }

        const { version } = await response.json();
        const currentVersion = localStorage.getItem('appVersion');

        if (currentVersion && currentVersion !== version) {
          // alert('A new version is available. Reloading...');
          localStorage.setItem('appVersion', version);
          window.location.reload();
        } else {
          localStorage.setItem('appVersion', version);
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    };

    checkForUpdates(); // Initial check on load
  }, []);


  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state?.fromStripe) {
        // Redirect to fail page
        window.location.href = '/payment_failed';
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      {AllDataResponse == "1" ? (
        <>
          {
            !MaintenanceMode &&
            <>
              {showNavbar && <Navbar />}
              <Routes>
                {/* <Route path="/" element={<Navigate to="/" />} /> */}
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/country_plans" element={<SelectedCountryPlan />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/payment_success/:userId" element={<PaymentSuccess />} />
                <Route path="/payment_failed" element={<PaymentCancel />} />
                <Route path="/business_section" element={<Business />} />
                <Route path="/all_countries&regions" element={<Country_List />} />
                <Route path='/account_information' element={<Account />}></Route>
                <Route path='/region_country_list' element={<RegionCountryList />}></Route>
                <Route path='/region_plans' element={<SelectedRegionPlans />}></Route>
              </Routes>
              {/* {showNavbar && <FAQ />} */}
            </>
          }

          < Modal open={MaintenanceMode}>
            <Box sx={modalStyle2} className="MaintenanceModePopUp" >
              <H2>
                Stay Connected.. <br />
                <span>COMING SOON !!</span>
              </H2>

              <img src={MaintenanceImaage} />

              <p>{MaintenanceModeText}</p>

            </Box>
          </ Modal>


        </>
      ) : (
        <div style={{ display: 'flex', minHeight: '80vh', alignItems: 'center', justifyContent: 'center' }}>
          <img width={200} src={LoaderImage}
            style={{
              animation: "Loader 2s infinite",
              transition: '0.5s ease all'
            }}
          />
        </div>
      )}

      <Modal open={OpenSessionTimeOutModal}>
        <Box className="SessionTimeOutModal" >
          {Loading === true ? (
            <div
              style={{
                height: isMobile ? "300px" : "500px",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Bars
                height="28"
                width="28"
                color="#9c88ff"
                ariaLabel="bars-loading"
                position="absolute"
                visible={true}
              />
            </div>
          ) : (
            <div id="Content">
              <img
                src={SessionTimeOutImg}
                style={{
                }}
              />

              <H2 style={{ textAlign: "center" }}>
                Your session has expired ..... !!!
                <br /> Please log in again to continue.
              </H2>

              <Button
                style={{ color: "red", fontSize: "16px", marginBottom: '20px' }}
                variant="outlined"
                color="inherit"
                onClick={HandleLogout}
              >
                Log In Again
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default App;