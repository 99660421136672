import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import paymentSuccessGif from '../../Assets/Business_section.png'
import SuccessIcon from '../../Assets/check.png'
import { Bars } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//These imports are for the reports download
import jsPDF from "jspdf";
import "jspdf-autotable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF"
};

const PaymentSuccess = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const [openBox, setOpenBox] = useState(true);
    const { userId } = useParams();
    const navigate = useNavigate();

    const [billingListShowLoader, setBillingListShowLoader] = useState(true);
    const [billingPrice, setBillingPrice] = useState("");
    const [billingCurrency, setBillingCurrency] = useState("");
    const [billingPlanName, setBillingPlanName] = useState("");
    const [billingSuccessPayment, setBillingSuccessPayment] = useState("");
    const [billingTransactionId, setBillingTransactionId] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [billingPaymentType, setBillingPaymentType] = useState("");
    const [apiCount, setApiCount] = useState('0')

    // getting the getpayment resposne status
    const [AllDataResponse, setAllDataResponse] = useState('')

    const UserUuid = localStorage.getItem("UserUuid");

    const handleChooseIccidApi = (ChooseIccids) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "iccid": ChooseIccids
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/choose_iccid`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
            })
            .catch((error) => console.error(error));
    }

    const fetchSessionData = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": userId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/fetch_session`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                
                if ( result.status == 1) {
                    showBillingData(result.session_status)
                }else{
                    setApiCount('1')
                    setBillingListShowLoader(false);
                }
            })
            .catch((error) => console.error(error));

    }

    const showBillingData = async (sessionData) => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify(

            {
                "user_uuid": userId,
                "region": sessionData.region,
                "country": sessionData.country,
                "days": sessionData.days,
                "data": sessionData.data,
                "iccid": sessionData.iccid,
                "plantype_id": sessionData.plantype_id,
                "plan_name": sessionData.plan_name,
                "price": sessionData.price,
                "plan_type": sessionData.plan_type
            }
        );

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(`${REACT_APP_BASE_URL}/get_payment_details`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            
            setBillingListShowLoader(false);
            setBillingPrice(result.session_url.price);
            setBillingCurrency(result.session_url.currency);
            setBillingPlanName(result.session_url.plan_name);
            setBillingSuccessPayment(result.payment_details.status);
            setBillingTransactionId(result.payment_details.id);
            setBillingEmail(result.payment_details.billing_details.email);
            setBillingPaymentType(result.payment_details.payment_methods.type);
            setAllDataResponse(result.status == 1 || 2);
            handleChooseIccidApi(result.data.iccid);

        } catch (error) {
            setBillingListShowLoader(false);
            console.error("Error fetching billing data:", error);
        }
    };


    useEffect(() => {
        if (openBox) {
            setTimeout(() => {
                fetchSessionData()
                // showBillingData();
            }, 500);
        }
    }, []);

    const generatePDF = () => {
        const report = new jsPDF("portrait", "mm", "a4");

        const data = [];
        const headers = ['Field', 'Value'];

        const tableData = document.querySelectorAll('.payment_detail p');
        tableData.forEach(row => {
            const field = row.childNodes[0].textContent.trim();
            const value = row.childNodes[1].textContent.trim();
            data.push([field, value]);
        });

        report.autoTable({
            head: [headers],
            body: data,
            theme: "striped",
            styles: { fontSize: 8 },
            margin: { top: 15 },
        });
        report.save("Payment_Success_Details.pdf");
    };

    const handleHomenavigate = () => {
        navigate('/');
        localStorage.removeItem("BillingCountryCode")
        localStorage.removeItem("BillingDays")
        localStorage.removeItem("BillingData")
        localStorage.removeItem("BillingPrice")
        localStorage.removeItem("BillingPlan")
        localStorage.removeItem("BillingUid")
        localStorage.removeItem("PlanType")
        localStorage.removeItem("BillingRegionCode")
        localStorage.removeItem("PlanUID")
        localStorage.removeItem("RegionName")
        localStorage.removeItem("ICCID")
    }

    const handleNavigateMyDigiesim = () => {
    navigate('/account_information');
        localStorage.removeItem("BillingCountryCode")
        localStorage.removeItem("BillingDays")
        localStorage.removeItem("BillingData")
        localStorage.removeItem("BillingPrice")
        localStorage.removeItem("BillingPlan")
        localStorage.removeItem("BillingUid")
        localStorage.removeItem("PlanType")
        localStorage.removeItem("BillingRegionCode")
        localStorage.removeItem("PlanUID")
        localStorage.removeItem("RegionName")
        localStorage.removeItem("ICCID")
    }

    return (
        <>
            <Modal open={openBox}>
                <Box sx={style} className="SuccessPaymentModalStyling">
                    <img src={paymentSuccessGif} className="success_gif" alt="Payment Success" />
                    {billingListShowLoader ?
                          (
                            <div
                                style={{
                                    width: "100%",
                                    height: "20vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Bars height="30" width="30" color="#37BAE4" visible={true} />
                            </div>
                        ) : (
                            apiCount === "1" ? (
                                <>
                                <div className="paymnet_main">
                                    <div className="payment_detail" id="Table-Data">
                                        <h2>Payment Successful    <img src={SuccessIcon} className='SuccessIcon' alt="Sucess icon" /></h2>

                                        <hr />

                                        <p>
                                            Your payment is successful..
                                            <span> {billingPlanName}</span>
                                        </p>
                                    </div>

                                    <p> You will receive email instructions.
                                    Please also check your junk or spam folder if you don't see it in your inbox.</p>

                                    <div style={{
                                        display: "flex",
                                        padding: "15px 0px",
                                        width: '50%',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Button
                                            style={{ color: "red", transform: "scale(.9)" }}
                                            variant="outlined"
                                            color="inherit"
                                            onClick={handleNavigateMyDigiesim}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                                </>
                            ) : (

                                <div className="paymnet_main">
                                    <div className="payment_detail" id="Table-Data">
                                        <h2>Payment Successful    <img src={SuccessIcon} className='SuccessIcon' alt="Sucess icon" /></h2>

                                        <hr />
                                        {AllDataResponse === 1 &&

                                            <>
                                                <p>
                                                    Transaction Id :
                                                    <span> {billingTransactionId}</span>

                                                </p>
                                                <p>
                                                    Payment Type :
                                                    <span style={{ textTransform: 'capitalize' }}> {billingPaymentType} </span>
                                                </p>
                                                <p>
                                                    Email ID :
                                                    <span> {billingEmail} </span>
                                                </p>
                                            </>

                                        }
                                        <p>
                                            Package Name :
                                            <span> {billingPlanName}</span>
                                        </p>
                                        <p>
                                            Amount :
                                            <span>
                                                {billingCurrency === 'eur' ? `€ ${billingPrice}` :
                                                    billingCurrency === 'usd' ? `$ ${billingPrice}` :
                                                        billingCurrency === 'gbp' ? `£ ${billingPrice}` : ''}
                                            </span>
                                        </p>
                                        <p>
                                            Status Payment :
                                            <span style={{ textTransform: 'capitalize' }}> {billingSuccessPayment}</span>
                                        </p>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        padding: "15px 0px",
                                        width: '50%',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Button
                                            style={{ color: "Green", transform: "scale(.9)" }}
                                            variant="outlined"
                                            color="inherit"
                                            onClick={generatePDF}
                                        >
                                            Print
                                        </Button>

                                        <Button
                                            style={{ color: "red", transform: "scale(.9)" }}
                                            variant="outlined"
                                            color="inherit"
                                            onClick={handleHomenavigate}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                    <p> You will receive email instructions.
                                        Please also check your junk or spam folder if you don't see it in your inbox.</p>
                                </div>

                            )
                        )}
                </Box>
            </Modal>
        </>
    );
};

export default PaymentSuccess;